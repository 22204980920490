import React from 'react';
import * as s from './s.module.css';

import Header from './Header';
import Overview from './Overview';

interface Props {
    location: any,
}

class OverviewPage extends React.Component<Props, {}> {
    render() {
        return (
            <section className={s.sectionContainer}>
                <div className={s.sectionContent}>
                    <Header title={'Token Economy'} location={this.props.location}/>
                    <Overview />
                </div>
            </section>
        )
    }
}

export default OverviewPage;