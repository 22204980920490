import React from 'react';
import * as s from './s.module.css';
import cx from 'classnames';

class Overview extends React.Component {
    render() {
        return (
            <section className={s.overviewContainer}>
                <div className={s.overviewContent}>
                    <p><span className={s.bold}>VIRTUE </span> uses several tokens accoss both real-money and play-to-earn games.</p>
                    <div className={s.tableContainer}>
                        <div className={cx(s.myTable)}>
                            <div className={cx(s.rowHeader, s.roundUpper)}>
                                <div className={cx(s.w_16)}>
                                    <p>&nbsp;</p>
                                </div>
                                <div className={s.separator}/>
                                <div className={cx(s.w_21)}>
                                    <p>Definition</p>
                                </div>
                                <div className={s.separator}/>
                                <div className={cx(s.w_21)}>
                                    <p>Price</p>
                                </div>
                                <div className={s.separator}/>

                                <div className={cx(s.w_21)}>
                                    <p>How to Get</p>
                                </div>
                                <div className={s.separator}/>

                                <div className={cx(s.w_21)}>
                                    <p>Utility</p>
                                </div>
                            </div>
                            <div className={cx(s.row, s.rowCenter)}>
                                <div className={cx(s.w_16, s.rowChild1)}>
                                    <p className={s.textCenter}>Bankroll chips</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild1)}>
                                    <p>Bankroll chips are the limited amount of free-to-play chips players receive when they register an account on Virtue. These chips are used to compete in Weekly Challenges to earn $GOLD. Bankroll chips are only used internally within the Virtue application: they can neither be deposited nor withdrawn.</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild1)}>
                                    <p className={s.bold}>No value</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild1)}>
                                    <p>	
                                        Sign-Up: <span className={s.bold}>500 chips</span>
                                        <br/>
                                        Play 10 tournaments in a Week: <span className={s.bold}>100 chips</span>
                                        <br/>
                                        Stake VPP: <span className={s.bold}>Receive 1000 chips</span>
                                    </p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild1)}>
                                    <p>Compete in Weekly Challenges to Earn <span className={s.bold}>$GOLD</span></p>
                                </div>
                            </div>
                            <div className={cx(s.row, s.rowCenter)}>
                                <div className={cx(s.w_16, s.rowChild2)}>
                                    <p className={s.textCenter}>$VPP Token</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild2)}>
                                    <p>The $VPP token can be staked by players to receive bonus chips or staked to become a Justice and earn ETH and $GOLD staking reward fees</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild2)}>
                                    <p><a target='_blank' className={s.link} href='https://coinmarketcap.com/currencies/virtue-poker/'>Coinmarketcap</a></p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild2)}>
                                    <p><span className={s.bold}>MEXC</span></p>
                                    <p><span className={s.bold}>Uniswap</span></p>
                                    <p><span className={s.bold}>PancakeSwap</span></p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild2)}>
                                    <p
                                        >Player Staking for Bonus Chips.
                                        <br/>
                                        Justice Stake for <span className={s.bold}>$GOLD</span> and <span className={s.bold}>ETH</span> Rewards.
                                        <br/>
                                        Governance (coming soon)
                                    </p>
                                </div>
                            </div>
                            <div className={cx(s.row, s.rowCenter)}>
                                <div className={cx(s.w_16, s.rowChild1)}>
                                    <p className={s.textCenter}>$GOLD Token</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild1)}>
                                    <p>The $GOLD token is Virtue's loyalty token that can be earned through P2E challenges and gameplay on ETH tables.</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild1)}>
                                    <p><span className={s.bold}>$1 USDT</span></p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild1)}>
                                    <p
                                        >Daily Jackpot, Weekly Challenge in P2E Games.
                                        <br/>
                                        Earned through play on ETH table games
                                    </p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild1)}>
                                    <p>Earned through gameplay, used to purchase NFT avatars, access to special promotions on $GOLD table games</p>
                                </div>    
                            </div>
                            <div className={cx(s.row, s.rowCenter)}>
                                <div className={cx(s.w_16, s.rowChild2)}>
                                    <p className={s.textCenter}>$ETH</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild2)}>
                                    <p>Ether or $ETH is the core token that powers the Ethereum blockchain network. $ETH is used to pay for "gas" which are the transactions costs for using the Ethereum network. These fees are paid to "miners"</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild2)}>
                                    <p><a target='_blank' className={s.link} href='https://coinmarketcap.com/currencies/ethereum/'>Coinmarketcap</a></p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild2)}>
                                    <p>Available on all major exchanges across the globe. The world’s second largest cryptocurrency by market capitalization</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild2)}>
                                    <p>Pay Gas on the Ethereum Network</p>
                                </div>
                            </div>
                            <div className={cx(s.row, s.rowCenter)}>
                                <div className={cx(s.w_16, s.rowChild1, s.roundDownLeft)}>
                                    <p className={s.textCenter}>NFT Avatars</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild1)}>
                                    <p>Virtue is conducting a Genesis Mint of NFT avatars that will enhance the $GOLD earnings for owners across both play-to-earn and real-money gaming and will be used as an access key to Virtue's Metaverse poker game.</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild1)}>
                                    <p>Genesis Mint of Virtue NFT will cost 150 $GOLD per avatar.</p>
                                    <p>Our upcoming Genesis Mint of NFT avatars will be 500 units of hand-designed unique art characters among five rarities that will change the utility players receive from their NFT.</p>                                    
                                </div>
                                <div className={cx(s.w_21, s.rowChild1)}>
                                    <p>All players can get early access to Genesis Mint of Virtue NFT through whitelisting.</p>
                                    <p>All players can purchase an NFT avatar using their $GOLD tokens.</p>
                                    <p>To get a whitelist spot, go to the Cashier in the Virtue App. </p>
                                    <p>The whitelist will be closed on June 30.</p>
                                </div>
                                <div className={cx(s.w_21, s.rowChild1, s.roundDownRight)}>
                                    <p>Increase $GOLD earnings through gameplay (gives one-time 2,000 Chips bonus once whitelisted/purchased.</p>
                                    <p>VIP access to a private Discord and exclusive access to special events (coming soon).</p>
                                    <p>As an access key to metaverse poker (coming soon).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Overview;
