import React from "react"

import Layout from "../../layout/default"
import Seo from "../../components/seo"
import OverviewPage from '../../components/TokenEconomy/overviewPage'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Overview" />
    <OverviewPage location={location}/>
  </Layout>
)

export default IndexPage
